@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;600;700&display=swap");

#root, html, body, .ant-app {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    border: none;
    background-color: #050115;
    font-family: 'Barlow', sans-serif;
}

.ant-btn.ant-btn-block {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

* {
    text-decoration: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}


.ant-card .ant-card-head {
    border-bottom: none;
}

.site-description-item-profile-wrapper {
    margin-bottom: 7px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
}

.ant-drawer-body p.site-description-item-profile-p {
    display: block;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    line-height: 1.5715;
}

.site-description-item-profile-p-label {
    display: inline-block;
    margin-inline-end: 8px;
    color: rgba(0, 0, 0, 0.85);
}

.ant-skeleton.ant-skeleton-element {
    display: flex !important;
}


.block {
    border-radius: 30px;
    position: relative;
    background: linear-gradient(0deg, #000, #272727);
}

.block:before, .block:after {
    border-radius: 30px;
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000);
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: steam 20s linear infinite;
}

@keyframes steam {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.block:after {
    filter: blur(50px);
}


.rotating-border {
    border-radius: 30px;
    position: relative;
}

.rotating-border:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 30px;
    background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094);
    background-size: 300% 300%;
    animation: rotate-border 2s linear infinite;
    z-index: -1;
}

@keyframes rotate-border {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}


a {
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

* {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}
